import { inject, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import {
  Conversation,
  Message,
  MessageCreateRequest,
  MessageUpdateRequest,
  User,
} from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthMessageModel } from '@sentinels/models';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

import { ReportingService } from './reporting.service';

@Injectable({ providedIn: 'root' })
export class ConversationsService {
  private afdb = inject(AngularFireDatabase);
  private reporting = inject(ReportingService);

  protected basePath = 'conversations';

  generateConversation(ref: firebase.database.Reference, id: string | null) {
    const createKey = id ?? ref.push().key;

    if (createKey) return ref.child(createKey);

    return null;
  }

  async create(request: MessageCreateRequest) {
    let conversationId: string | null;

    await Promise.all(
      request.participants.map(async (participant: User) => {
        const ref = this.afdb.database.ref(DBUtil.Conversation + `/${participant.uid}`);

        const conversation = this.generateConversation(
          ref,
          conversationId ?? request.conversationId,
        );

        if (conversation) {
          conversationId = conversation.key;

          await this.createInternal(request, participant, conversation).catch((e) => {
            console.error('Error creating conversation', e);
          });
        }
      }),
    );

    return true;
  }

  async createInternal(
    request: MessageCreateRequest,
    user: User,
    conversation: firebase.database.Reference,
  ) {
    if (!(await conversation.get()).exists()) {
      await conversation.set({
        participants: request.participants,
        muted: false,
        blocked: false,
      });
    }

    const message = request.message;

    message.isRead = request.senderId === user.uid;

    if (message.messageSent instanceof Date) {
      message.messageSent = message.messageSent.toJSON() as any;
    }

    await conversation.child('messages').push().set(message);

    return true;
  }

  async update(request: MessageUpdateRequest) {
    const conversation = request.conversationId;

    const user = request.userId;

    const path = DBUtil.Conversation + `/${user}`;

    const userRef = this.afdb.database.ref(path);

    const childRef = userRef.child(conversation);

    const updateObj: { [key: string]: boolean } = {};

    if (request.muted !== undefined) {
      updateObj['muted'] = request.muted;
    }

    if (request.blocked !== undefined) {
      updateObj['blocked'] = request.blocked;
    }

    if (request.reported !== undefined) {
      updateObj['reported'] = request.reported;
    }
    if (request.read !== undefined) {
      // TODO: This will need to be fixed when pagination is added.
      const messageVal = (await childRef.child('messages').get()).val();

      Object.entries(messageVal).forEach((keyval) => {
        const message = keyval[1] as Message;

        message.isRead = request.read!;
      });

      childRef.child('messages').update(messageVal);
    }

    const value = (await childRef.get()).val();

    await childRef.set({
      ...value,
      ...updateObj,
    });

    return true;
  }

  async get(data: { conversationId: string; userId: string }) {
    return new Observable<Conversation>((observer) => {
      const conversationPath = `${this.basePath}/${data.userId}/${data.conversationId}/`;

      this.afdb.database.ref(conversationPath).on('value', (snap) => {
        const conversation = {
          id: data.conversationId,
          ...snap.val(),
        };

        observer.next(conversation);
      });
    });
  }

  async reportConversation(
    conversationId: string,
    userId: string,
    reported: boolean,
    reportingUserId: string,
  ) {
    const contract: MessageUpdateRequest = {
      conversationId: conversationId,
      userId: userId,
      reported,
    };

    await this.update(contract);

    this.reporting.reportConversation(conversationId, reportingUserId);
  }

  async reportMessage(
    conversationId: string,
    message: GthMessageModel,
    reportingUserId: string,
  ) {
    this.reporting.reportMessage(conversationId, message.id, reportingUserId);
  }
}
